import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/global.css'
//import './assets/master.css'
import Vue3VideoPlayer from '@cloudgeek/vue3-video-player'
import '@cloudgeek/vue3-video-player/dist/vue3-video-player.css'
import mitt from 'mitt';
import {VueMasonryPlugin} from "vue-masonry/src/masonry-vue3.plugin";
import apiClient from "@/./http-common";


const emitter = mitt();

const app = createApp(App).use(router).use(Vue3VideoPlayer).provide('emitter', emitter);
app.config.globalProperties.emitter = emitter;
app.use(VueMasonryPlugin);
app.mount("#app");


