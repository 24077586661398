
    import {defineComponent, ref, inject} from "vue";
    import {container, openModal, pusModal} from "jenesius-vue-modal";
    import {CloseCircleOutline} from 'mdue';

    export default {
        name: "MediaOverlay",
        components: {CloseCircleOutline},
        props: {
            type: String,
            file: String,
            youtubeId: String,
            format: String,
        },
        setup(props, context) {
            const emitter = inject("emitter");

            const emitClose = () => {
                (emitter as any).emit('close-media');
            }

            const video = ref(null);

            const compressedPath = path => {
                let pathParts = path.split('/');
                return pathParts[0]+"/" + pathParts[1] + "/m4v/" + pathParts[2].replace("mp4", "m4v");
            }

            return {emitClose, compressedPath}
        },


    }
