
    import {defineComponent, ref, inject} from "vue";
    import {container, openModal, pusModal} from "jenesius-vue-modal";
    import axios from "axios";

    export default defineComponent({
        name: "EnterPassword",
        props: {
            admin: Boolean
        },
        emits: ['logged'],
        setup(props, context) {
            const password = ref("");
            const logged = ref(false);

            const emitter = inject("emitter");

            const loginError = ref(null);

            const sendPassword = () => {
                loginError.value = null;
                axios.post('/backend/login.php', {
                    password: password.value,
                    type: props.admin == true ? "Admin" : "User"
                })
                    .then(response => {
                        console.log(response);
                        (emitter as any).emit('logged');
                    }).catch(error => {
                    console.log(error);
                    loginError.value = "Wrong password!";
                });


            }

            return {password, logged, sendPassword, loginError};
        }
    })
