<template>
    <nav class="navbar navbar-expand-md navbar-dark bg-dark mb-4">
        <div class="container-fluid">
            <a class="navbar-brand" href="/">Main Page</a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"
                    aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarCollapse">
                <ul class="navbar-nav me-auto mb-2 mb-md-0">
                    <li class="nav-item">
                        <a v-bind:class="'nav-link '+(currentPage == 'grid'?'active':'')" aria-current="page" href="#"
                           @click.prevent="currentPage = 'grid'">
                            <grid></grid>
                            Items Grid</a>
                    </li>
                    <li class="nav-item">
                        <a v-bind:class="'nav-link '+(currentPage == 'passwords'?'active':'')"
                           @click.prevent="currentPage = 'passwords'" href="#">
                            <lock></lock>
                            Passwords</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>

    <main class="container">
        <div class="bg-light p-5 rounded" v-if="currentPage == 'grid'">
            <h1>Items Grid</h1>

            <div class="row">
                <div class="col">
                    <select v-model="newType" class="form-control" aria-label="Type" placeholder="Type"
                            required="required">
                        <option value="video">Video</option>
                        <option value="image">Image</option>
                        <option value="youtube">Youtube</option>
                    </select>
                </div>
                <div class="col" v-if="newType == 'video' || newType == 'image'">
                    <input type="file" id="item_file" ref="item_file" class="form-control"
                           @change="onChangeFileUpload()"/>
                </div>
                <div class="col">
                    <select v-model="newFormat" class="form-control" aria-label="Format" placeholder="Format"
                            required="required">
                        <option value="landscape">Landscape</option>
                        <option value="portrait">Portrait</option>
                    </select>
                </div>
                <div class="col" v-if="newType == 'youtube'">
                    <input type="text" class="form-control" placeholder="Youtube id" aria-label="Youtube id">
                </div>
                <div class="col">
                    <button type="button" class="btn btn-primary" @click="submitItem()">Submit</button>
                </div>
            </div>

            <draggable
                    tag="ul"
                    :list="items.values"
                    @start="drag=true"
                    @end="drag=false"
                    class="list-group mt-3"
                    ghost-class="ghost"
                    :move="checkMove"
                    item-key="id">
                <template #item="{element}">
                    <li class="list-group-item">
                        <div class="input-group">
                            <span>      <b>{{element.type}}</b></span> <span
                                v-if="element.file"><b>path:</b> {{element.file}} </span> <span
                                v-if="element.youtube_id"><b>youtube id:</b> {{element.youtube_id}}</span>
                            <span>
                        <delete-forever title="delete" @click="deleteItem(element.id,element.type)"></delete-forever>
                         	</span>
                        </div>
                    </li>
                </template>
            </draggable>
        </div>
    </main>

    <widget-container-modal/>
</template>

<script lang="ts">
    import {Grid, Lock, DeleteForever} from 'mdue';
    import {defineComponent, ref, onMounted, watch, inject, reactive} from "vue";
    import {container, openModal} from "jenesius-vue-modal";
    import EnterPassword from "@/components/EnterPassword.vue";
    import axios from "axios";
    import draggable from 'vuedraggable'

    export default defineComponent({
        name: "Admin",
        components: {Grid, Lock, DeleteForever, EnterPassword, WidgetContainerModal: container, draggable},

        setup() {
            //    const $vfm = inject('$vfm')
            let currentPage = ref("grid");

            const preventHide = () => {
                //    console.log('clickkk');
            }

            let logged = ref(false);

            const emitter = inject("emitter");

            onMounted(async () => {
                //  $vfm.show('example')
                const modal = await openModal(EnterPassword, {admin: true});

                modal.onclose = () => {
                    if (logged.value == false)
                        return false; //The modal window will be closed after five attempts.
                }

                (emitter as any).on('logged', data => {
                    logged.value = true;
                    modal.close();
                });
            });

            let items = reactive([]);

            const loadItemsFromAPI = () => {
                axios.get('/backend/items.php')
                    .then(function (response) {
                        // handle success
                        items.values = response.data.items;
                    })
                    .catch(function (error) {
                        alert("Error loading items!");
                        console.log(error);
                    })
                    .then(function () {
                        // always executed
                    });
            }

            loadItemsFromAPI();

            let newType = ref('video');
            let newFile = null;
            let newYoutubeId = ref(null);
            let newFormat = ref('landscape');
            const item_file = ref(null);

            const onChangeFileUpload = () => {
                newFile = (event.target as any).files[0];
            }

            const submitItem = () => {
                // item_file.value.reset();
                //newFile.reset();
                if ((newType.value == 'video' || newType.value == 'image') && newFile == null) {
                    alert("Missing file"!)
                    return;
                }
                if ((newType.value == 'youtube') && newYoutubeId.value == null) {
                    alert("Missing youtube id"!)
                    return;
                }
                let formData = new FormData();
                formData.append('file', newFile);
                formData.append('type', newType.value);
                formData.append('format', newFormat.value);
                if (newYoutubeId.value !== null)
                    formData.append('youtube_id', newYoutubeId.value ? newYoutubeId.value : null);

                axios.post('/backend/items.php',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(function (data) {
                    //newType.value = 'video';
                    newFile = null;
                    newYoutubeId.value = null;
                    item_file.value = null;
                    loadItemsFromAPI();
                })
                    .catch(function (err) {
                        alert("Something went wrong uploading that item");
                        console.log(err);
                    });
            }

            let drag = ref(false);
            const checkMove = (evt) => {
                console.log(evt);
            }

            let deleteItem = (id, type) => {
                if (confirm("Delete " + type + "?"))
                    axios.delete("backend/items.php", {
                        data: {
                            id: id
                        }
                    })
                        .then(function (data) {
                            loadItemsFromAPI();
                        })
                        .catch(function (err) {
                            alert("Something went wrong deleting that item");
                            console.log(err);
                        });
            }

            return {
                currentPage, preventHide, logged, submitItem, newType, newFile, newYoutubeId, onChangeFileUpload, items,
                drag, newFormat, deleteItem
            };
        },
    });


</script>

<style scoped>
    .ghost {
        opacity: 0.5;
        background: #c8ebfb;
    }

    .input-group {
        display: table;
        width: 100%;
    }

    .input-group span {
        display: table-cell;
        border: 1px solid #ccc;
        padding: 0 8px;
        vertical-align: middle;
    }

    .input-group span:last-child {
        width: 40px;
        font-size: 22px;
    }

    .input-group span:last-child svg {
        cursor: pointer;
    }

</style>