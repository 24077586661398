
    import {defineComponent, inject, onMounted, reactive,} from "vue";
    import {ref} from "vue";
    import YouTube from 'vue3-youtube';
    import {container, openModal, closeModal} from "jenesius-vue-modal";
    import EnterPassword from "@/components/EnterPassword.vue";
    import MediaOverlay from "@/components/MediaOverlay.vue";
    import axios from "axios";
    import {useRoute} from 'vue-router';
    import EndMessage from "@/components/EndMessage.vue";

    export default defineComponent({
        name: "Home",
        components: {
            YouTube, EnterPassword, WidgetContainerModal: container, MediaOverlay, EndMessage
        },

        setup() {
            const route = useRoute();

            let displayedAmount = ref(0);

            let items = reactive([]);


            // console.log(route.query);

            const loadItemsFromAPI = () => {
                axios.get('/backend/items.php')
                    .then(function (response) {
                        //   items = response.data.items;
                        for (let i = 0; i < response.data.items.length; i++) {
                            //console.log(response.data.items[i]);
                            items.push({
                                id: response.data.items[i].id,
                                type: response.data.items[i].type,
                                file: response.data.items[i].file,
                                youtube_id: response.data.items[i].youtube_id,
                                format: response.data.items[i].format,
                                thumb_path: response.data.items[i].thumb_path,
                                hidden: route.query.display_all ? false : true,
                            });
                            if (route.query.display_all)
                                displayedAmount.value++;
                            // items[i].hidden = true;
                        }

                    })
                    .catch(function (error) {
                        alert("Error loading items!");
                        console.log(error);
                    })
                    .then(function () {
                        // always executed
                    });
            }

            loadItemsFromAPI();

            const typeClass = type => {
                switch (type) {
                    case "video":
                        return "player-container";
                    case "image":
                        return "image-container";
                    case "youtube":
                        return "youtube-container";
                }
            }

            const logged = ref(false);

            const emitter = inject("emitter");

            onMounted(async () => {
                const modal = await openModal(EnterPassword, {admin: false});
                modal.onclose = () => {
                    if (logged.value == false)
                        return false; //The modal window will be closed after five attempts.
                }
                (emitter as any).on('logged', data => {
                    logged.value = true;
                    modal.close();
                });
            });

            const mediaModal = ref(null);

            const itemDisplay = index => {
                if (items[index].hidden)
                    displayedAmount.value++;
                items[index].hidden = false;
                // console.log(displayedAmount.value, items.length);

                let mediaModal = openModal(MediaOverlay, {
                    type: items[index].type,
                    file: items[index].file,
                    youtubeId: items[index].youtube_id,
                    format: items[index].format
                });

                (emitter as any).on('close-media', data => {

                    closeModal(displayedAmount.value, items.length, endMessageDisplayed.value);
                    if (displayedAmount.value == items.length && endMessageDisplayed.value == false && !route.query.display_all) {
                        let endModal = openModal(EndMessage, {});
                        endMessageDisplayed.value = true;
                    }
                });
            }

            const compressedPath = path => {
                let pathParts = path.split('/');
                return pathParts[0] + "/" + pathParts[1] + "/m4v/" + pathParts[2].replace("mp4", "m4v");
            }

            const endMessageDisplayed = ref(false);


            return {
                items, itemDisplay, typeClass, logged, displayedAmount, mediaModal, compressedPath, endMessageDisplayed
            }
        }

    });
