<template>
    <div class="home">
        <transition name="fade">
            <div id="click_anywhere" v-if="logged && displayedAmount == 0">Click anywhere...</div>
        </transition>
        <transition name="fade">
            <div id="click_anywhere_sec" v-if="logged && displayedAmount == 0">(and if that
                doesn't work, click somewhere else)
            </div>
        </transition>
        <div class="fullscreen" id="main_grid">

            <ul class="adaptive-gallery">
                <li v-for="(item, index) in items" @click.prevent="itemDisplay(index)">
                    <!-- <vue3-video-player v-if="item.type == 'video'" loading="lazy"
                                        v-bind:src="item.file"></vue3-video-player> -->
                    <img v-if="item.thumb_path" v-bind:src="item.thumb_path" class="img-fluid" loading="lazy"
                         v-bind:class="(item.hidden?'hidden':'')"/>
                    <template v-else>
                        <video v-if="item.type == 'video'" preload="metadata" muted
                               v-bind:class="(item.hidden?'hidden':'')" webkit-playsinline playsinline>
                            <source v-bind:src="compressedPath(item.file)+'#t=0.1'" type="video/mp4"
                                    media="all and (max-width:1020px)">
                            <source v-bind:src="item.file+'#t=0.1'" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>

                        <img v-if="item.type=='image'" v-bind:src="item.file" loading="lazy"
                             v-bind:class="(item.hidden?'hidden':'')"/>
                        <iframe v-if="item.type=='youtube'" width="100%" height="auto"
                                v-bind:class="(item.hidden?'hidden':'')"
                                v-bind:src="'https://www.youtube.com/embed/'+item.youtube_id"
                                title="YouTube video player" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen></iframe>
                    </template>
                </li>
                <li>

                </li>
            </ul>

        </div>
        <!--  -->

    </div>
    <widget-container-modal/>
</template>

<script lang="ts">
    import {defineComponent, inject, onMounted, reactive,} from "vue";
    import {ref} from "vue";
    import YouTube from 'vue3-youtube';
    import {container, openModal, closeModal} from "jenesius-vue-modal";
    import EnterPassword from "@/components/EnterPassword.vue";
    import MediaOverlay from "@/components/MediaOverlay.vue";
    import axios from "axios";
    import {useRoute} from 'vue-router';
    import EndMessage from "@/components/EndMessage.vue";

    export default defineComponent({
        name: "Home",
        components: {
            YouTube, EnterPassword, WidgetContainerModal: container, MediaOverlay, EndMessage
        },

        setup() {
            const route = useRoute();

            let displayedAmount = ref(0);

            let items = reactive([]);


            // console.log(route.query);

            const loadItemsFromAPI = () => {
                axios.get('/backend/items.php')
                    .then(function (response) {
                        //   items = response.data.items;
                        for (let i = 0; i < response.data.items.length; i++) {
                            //console.log(response.data.items[i]);
                            items.push({
                                id: response.data.items[i].id,
                                type: response.data.items[i].type,
                                file: response.data.items[i].file,
                                youtube_id: response.data.items[i].youtube_id,
                                format: response.data.items[i].format,
                                thumb_path: response.data.items[i].thumb_path,
                                hidden: route.query.display_all ? false : true,
                            });
                            if (route.query.display_all)
                                displayedAmount.value++;
                            // items[i].hidden = true;
                        }

                    })
                    .catch(function (error) {
                        alert("Error loading items!");
                        console.log(error);
                    })
                    .then(function () {
                        // always executed
                    });
            }

            loadItemsFromAPI();

            const typeClass = type => {
                switch (type) {
                    case "video":
                        return "player-container";
                    case "image":
                        return "image-container";
                    case "youtube":
                        return "youtube-container";
                }
            }

            const logged = ref(false);

            const emitter = inject("emitter");

            onMounted(async () => {
                const modal = await openModal(EnterPassword, {admin: false});
                modal.onclose = () => {
                    if (logged.value == false)
                        return false; //The modal window will be closed after five attempts.
                }
                (emitter as any).on('logged', data => {
                    logged.value = true;
                    modal.close();
                });
            });

            const mediaModal = ref(null);

            const itemDisplay = index => {
                if (items[index].hidden)
                    displayedAmount.value++;
                items[index].hidden = false;
                // console.log(displayedAmount.value, items.length);

                let mediaModal = openModal(MediaOverlay, {
                    type: items[index].type,
                    file: items[index].file,
                    youtubeId: items[index].youtube_id,
                    format: items[index].format
                });

                (emitter as any).on('close-media', data => {

                    closeModal(displayedAmount.value, items.length, endMessageDisplayed.value);
                    if (displayedAmount.value == items.length && endMessageDisplayed.value == false && !route.query.display_all) {
                        let endModal = openModal(EndMessage, {});
                        endMessageDisplayed.value = true;
                    }
                });
            }

            const compressedPath = path => {
                let pathParts = path.split('/');
                return pathParts[0] + "/" + pathParts[1] + "/m4v/" + pathParts[2].replace("mp4", "m4v");
            }

            const endMessageDisplayed = ref(false);


            return {
                items, itemDisplay, typeClass, logged, displayedAmount, mediaModal, compressedPath, endMessageDisplayed
            }
        }

    });
</script>
<style>


    body {
        /*    background-size: 50px 50px;*/
        background-color: #560BAD;
    }

    .btn-primary {
        background-color: #F72585;
        border-color: #F72585;
    }

    .btn-check:active + .btn-primary, .btn-check:checked + .btn-primary, .btn-primary.active, .btn-primary:active, .show > .btn-primary.dropdown-toggle {
        background-color: #B5179E;
        border-color: #B5179E;
    }

    .btn-check:focus + .btn-primary, .btn-primary:focus {
        background-color: #B5179E;
        border-color: #B5179E;
    }

    .btn-check:focus + .btn-primary, .btn-primary:focus, .btn-primary:hover {
        background-color: #B5179E;
        border-color: #B5179E;
    }

    .home {
        /* background: #3A0CA3 url("/images/bg.png") repeat;*/


    }

    #main_grid {

        min-height: 100%;

        /*  padding-left: 6px;
          padding-right: 6px;*/

    }


    .grid-item {
        position: relative;
    }

    .item-wrapper {
        position: relative;
        box-sizing: border-box;
        /* max-width: 100%;
         max-height: 100%;*/
    }

    .player-container, .item-overlay, .image-container, .youtube-container {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .player-container, .youtube-container, .image-container {
        padding: 6px;
        box-sizing: border-box;
    }

    .item-overlay {
        z-index: 1009;
        background-color: rgba(0, 0, 0, 0.20);
        transition: 0.3s all ease-in;
    }

    .item-overlay:hover {
        background-color: transparent;
        transition: 0.3s all ease-out;
    }

    .home {
        height: 100%;
    }

    .image-container {

    }

    .image-container img {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }

    .youtube-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .opaque {
        background-color: #3A0CA3 !important;
    }

    .item-container {
        /* max-width: 180px;*/
        /*   max-height: 180px;*/
        /*overflow: hidden;*/
    }

    .portrait {
        /* max-width: 101px;*/
    }


    #click_anywhere {
        position: fixed;
        width: 500px;
        height: 200px;
        top: 50%;
        left: 50%;
        margin-top: -100px; /* Negative half of height. */
        margin-left: -250px; /* Negative half of width. */
        color: white;
        font-size: 42px;
        pointer-events: none;
        /* font-weight: bold;*/
        text-align: center;
        vertical-align: middle;
        line-height: 200px;
        z-index: 10000;
        font-family: 'Cairo', sans-serif;
    }

    #click_anywhere_sec {
        position: fixed;
        width: 500px;
        height: 200px;
        top: 60%;
        left: 50%;
        margin-top: -100px; /* Negative half of height. */
        margin-left: -250px; /* Negative half of width. */
        color: white;
        pointer-events: none;
        /* font-weight: bold;*/
        text-align: center;
        vertical-align: middle;
        line-height: 200px;
        z-index: 10000;
        font-family: 'Cairo', sans-serif;
        font-size: 24px;
    }


    @media (max-width: 576px) {
        #click_anywhere {
            font-size: 34px;
        }

        #click_anywhere_sec {
            display: none; /**fuck it**/
        }
    }

    .hidden {
        visibility: hidden;
    }


</style>

