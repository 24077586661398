<template>
    <form>
        <div class="mb-3">
            <label for="exampleInputPassword1" v-bind:class="'form-label '+(admin?'':'text-white')">Enter
                {{admin?"Admin ":""}}Password</label>
            <input type="password" class="form-control" id="exampleInputPassword1" v-model="password">
        </div>
        <button type="submit" class="btn btn-primary" @click.prevent="sendPassword()">Submit</button>
        <div class="alert alert-danger mt-3" role="alert" v-if="loginError">
            {{loginError}}
        </div>
        <div class="mt-4 text-center">
            <router-link to="/like-form" id="like_link" target="_blank">
                CLICK HERE IF YOU LIKED THIS
            </router-link>
        </div>
    </form>
</template>

<script lang="ts">
    import {defineComponent, ref, inject} from "vue";
    import {container, openModal, pusModal} from "jenesius-vue-modal";
    import axios from "axios";

    export default defineComponent({
        name: "EnterPassword",
        props: {
            admin: Boolean
        },
        emits: ['logged'],
        setup(props, context) {
            const password = ref("");
            const logged = ref(false);

            const emitter = inject("emitter");

            const loginError = ref(null);

            const sendPassword = () => {
                loginError.value = null;
                axios.post('/backend/login.php', {
                    password: password.value,
                    type: props.admin == true ? "Admin" : "User"
                })
                    .then(response => {
                        console.log(response);
                        (emitter as any).emit('logged');
                    }).catch(error => {
                    console.log(error);
                    loginError.value = "Wrong password!";
                });


            }

            return {password, logged, sendPassword, loginError};
        }
    })
</script>

<style scoped>

    label {
        /*font-family: 'Fredoka One', cursive;*/
    }

    #like_link {
        color: white;
        font-family: 'Fredoka One', cursive;
        text-decoration: none;
    }

    #like_link:hover {
        color: #e7e6e6;
    }
</style>