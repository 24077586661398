<template>
    <div id="like_form">
        <div id="form_wrapper" class="text-center">
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSedCyUrRCcnLcTvvN1nCDcgNnHl3fGT2AYR1KzzD0CJgPKPAA/viewform?embedded=true"
                    width="640" height="1270" frameborder="0" marginheight="0" marginwidth="0">Loading…
            </iframe>
        </div>
    </div>
</template>

<script>
    export default {
        name: "LikeForm"
    }
</script>

<style scoped>
    #like_form {
        background-color: rgb(240, 235, 248);
    }

    #form_wrapper {
        width: auto;
    }
</style>