<template>
    <div id="media_container" v-bind:class="type+'-media-container'" @click="emitClose()">

        <!--     <video v-if="type == 'video'" @ended="emitClose()" autoplay v-on:click.stop  webkit-playsinline
                    v-bind:src="file"  v-on:ended="emitClose" playsinline type="video/mp4" preload="none"></video> -->
        <video v-if="type == 'video'" @ended="emitClose()" controls v-on:click.stop webkit-playsinline autoplay
               playsinline>
            <source v-bind:src="compressedPath(file)" type="video/mp4" media="all and (max-width:1030px)">
            <source v-bind:src="file" type="video/mp4">
            Your browser does not support the video tag.
        </video>
        <img v-if="type=='image'" v-bind:src="file+'#t=0.1'" class="img-fluid" v-on:click.stop/>
        <iframe v-if="type=='youtube'" width="100%" height="auto"
                v-bind:src="'https://www.youtube.com/embed/'+youtubeId"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
        <close-circle-outline @click="emitClose()" class="close" title="close"></close-circle-outline>
    </div>
</template>

<script lang="ts">
    import {defineComponent, ref, inject} from "vue";
    import {container, openModal, pusModal} from "jenesius-vue-modal";
    import {CloseCircleOutline} from 'mdue';

    export default {
        name: "MediaOverlay",
        components: {CloseCircleOutline},
        props: {
            type: String,
            file: String,
            youtubeId: String,
            format: String,
        },
        setup(props, context) {
            const emitter = inject("emitter");

            const emitClose = () => {
                (emitter as any).emit('close-media');
            }

            const video = ref(null);

            const compressedPath = path => {
                let pathParts = path.split('/');
                return pathParts[0]+"/" + pathParts[1] + "/m4v/" + pathParts[2].replace("mp4", "m4v");
            }

            return {emitClose, compressedPath}
        },


    }
</script>

<style scoped>
    #media_container {
        margin: 3%;
        padding: 20px;
        max-width: 94%;
        max-height: 94%;
        box-sizing: border-box;
        overflow: auto;
        min-width: 330px;
        height: 100%;
        width: 100%;
        vertical-align: middle;
        position: relative;
    }

    /* .video-media-container {
         width: auto !important;
         height: auto !important;
     }*/

    #media_container img, #media_container video {
        max-height: 100%;
        max-width: 100%;
        width: auto;
        height: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }

    #media_container .vcp-container {
        max-height: 100%;

    }

    .modal-container {
        background-color: rgb(0, 0, 0) !important;
    }

    .close {
        position: absolute;
        top: 0;
        right: 0;
        float: left;
        color: #F72585;
        font-size: 48px;
        cursor: pointer;
    }
</style>