
    import {Grid, Lock, DeleteForever} from 'mdue';
    import {defineComponent, ref, onMounted, watch, inject, reactive} from "vue";
    import {container, openModal} from "jenesius-vue-modal";
    import EnterPassword from "@/components/EnterPassword.vue";
    import axios from "axios";
    import draggable from 'vuedraggable'

    export default defineComponent({
        name: "Admin",
        components: {Grid, Lock, DeleteForever, EnterPassword, WidgetContainerModal: container, draggable},

        setup() {
            //    const $vfm = inject('$vfm')
            let currentPage = ref("grid");

            const preventHide = () => {
                //    console.log('clickkk');
            }

            let logged = ref(false);

            const emitter = inject("emitter");

            onMounted(async () => {
                //  $vfm.show('example')
                const modal = await openModal(EnterPassword, {admin: true});

                modal.onclose = () => {
                    if (logged.value == false)
                        return false; //The modal window will be closed after five attempts.
                }

                (emitter as any).on('logged', data => {
                    logged.value = true;
                    modal.close();
                });
            });

            let items = reactive([]);

            const loadItemsFromAPI = () => {
                axios.get('/backend/items.php')
                    .then(function (response) {
                        // handle success
                        items.values = response.data.items;
                    })
                    .catch(function (error) {
                        alert("Error loading items!");
                        console.log(error);
                    })
                    .then(function () {
                        // always executed
                    });
            }

            loadItemsFromAPI();

            let newType = ref('video');
            let newFile = null;
            let newYoutubeId = ref(null);
            let newFormat = ref('landscape');
            const item_file = ref(null);

            const onChangeFileUpload = () => {
                newFile = (event.target as any).files[0];
            }

            const submitItem = () => {
                // item_file.value.reset();
                //newFile.reset();
                if ((newType.value == 'video' || newType.value == 'image') && newFile == null) {
                    alert("Missing file"!)
                    return;
                }
                if ((newType.value == 'youtube') && newYoutubeId.value == null) {
                    alert("Missing youtube id"!)
                    return;
                }
                let formData = new FormData();
                formData.append('file', newFile);
                formData.append('type', newType.value);
                formData.append('format', newFormat.value);
                if (newYoutubeId.value !== null)
                    formData.append('youtube_id', newYoutubeId.value ? newYoutubeId.value : null);

                axios.post('/backend/items.php',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(function (data) {
                    //newType.value = 'video';
                    newFile = null;
                    newYoutubeId.value = null;
                    item_file.value = null;
                    loadItemsFromAPI();
                })
                    .catch(function (err) {
                        alert("Something went wrong uploading that item");
                        console.log(err);
                    });
            }

            let drag = ref(false);
            const checkMove = (evt) => {
                console.log(evt);
            }

            let deleteItem = (id, type) => {
                if (confirm("Delete " + type + "?"))
                    axios.delete("backend/items.php", {
                        data: {
                            id: id
                        }
                    })
                        .then(function (data) {
                            loadItemsFromAPI();
                        })
                        .catch(function (err) {
                            alert("Something went wrong deleting that item");
                            console.log(err);
                        });
            }

            return {
                currentPage, preventHide, logged, submitItem, newType, newFile, newYoutubeId, onChangeFileUpload, items,
                drag, newFormat, deleteItem
            };
        },
    });


