<template>
    <div id="end_wrapper">
        <h1>HAPPY 80TH BIRTHDAY JAN!</h1>
    </div>
</template>

<script lang="ts">
    import {defineComponent, ref, inject} from "vue";
    import {container, openModal, pusModal} from "jenesius-vue-modal";
    import {} from 'mdue';

    export default {
        name: "EndMessage",
        components: {},
        props: {},
        setup(props, context) {
            const emitter = inject("emitter");

            const emitClose = () => {
                (emitter as any).emit('close-media');
            }

            const video = ref(null);

            const compressedPath = path => {
                let pathParts = path.split('/');
                return pathParts[0] + "/" + pathParts[1] + "/m4v/" + pathParts[2].replace("mp4", "m4v");
            }

            return {emitClose, compressedPath}
        },


    }
</script>

<style scoped>
    #end_wrapper {
        background-color: white;
        padding: 30px;
        text-align: center;
    }

    #end_wrapper h1 {
        font-family: 'Cairo', sans-serif;
        color: #480CA8;
        margin-top: 30px;
        margin-bottom: 30px;
        text-align: center;
    }

</style>